import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import Price from '@/views/price/Price.vue'
import Use from '@/views/use/Use.vue'
import SpecList from '@/views/speclist/SpecList.vue'
import ChoosePic from '@/views/choosepic/ChoosePic.vue'
import Edit from '@/views/edit/Edit.vue'
import Save from '@/views/save/Save.vue'
import Map from '@/views/map/Map.vue'
import Specs from '@/views/specs/Specs.vue'
import ArticleList from '@/views/article/ArticleList.vue'
import ArticleDetail from '@/views/article-detail/ArticleDetail.vue'
import Download from '@/views/download/Download.vue'
import UAParser from "ua-parser-js";

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/price',
    name: 'price',
    component: Price
  },
  {
    path: '/download',
    name: 'download',
    component: HomeView
  },
  {
    path: '/use',
    name: 'use',
    component: Use
  },
  {
    path: '/speclist',
    name: 'speclist',
    component: SpecList
  },
  {
    path: '/choosepic',
    name: 'choosepic',
    component: ChoosePic
  },
  {
    path: '/edit',
    name: 'edit',
    component: Edit
  },
  {
    path: '/save',
    name: 'save',
    component: Save
  },
  {
    path: '/map',
    name: 'map',
    component: Map
  },
  {
    path: '/download',
    name: 'download',
    component: Download
  }, ,
  {
    path: '/specs',
    name: 'specs',
    component: Specs
  },
  {
    path: '/resource',
    name: 'resource',
    component: ArticleList
  },

  //动态路由匹配
  {
    path: '/resource/:path',
    name: 'articledetail',
    component: ArticleDetail,
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

//申明全局的导航守卫
// to：目标路由对象 from：当前导航正要离开的路由对象 next：是一个函数，表示放行
router.beforeEach(async (to, from, next) => {
  // if (to.meta.content) {
  //   let head = document.getElementsByTagName('head');
  //   let meta = document.createElement('meta');
  //   document.querySelector('meta[name ="description"]').setAttribute('content', to.meta.content.description)
  //   meta.content = to.meta.content;
  //   head[0].appendChild(meta)
  // }
  // if (to.meta.title) {
  //   document.title = to.meta.title;
  // }

  const parser = new UAParser();
  const result = parser.getResult();

  if (result.os.name === 'Android' || result.os.name === 'iOS' || result.os.name === 'BlackBerry' || result.os.name === 'Windows Phone') {
    const paths = ['/edit', '/save', '/map', '/choosepic', '/choosepic/']
    if (paths.includes(to.path)) {
      window.location.href = 'https://idphotos.jp/mobile';
    } else {
      window.location.href = 'https://idphotos.jp/mobile' + to.path;
    }
  } else {
    next();
  }
})
export default router
