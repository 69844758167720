<template>
  <div class="use-container">
    <div v-show="title_select === 0" class="content">
      <img src="@/assets/use/1.png" alt="" srcset="" />
      <img src="@/assets/use/2.png" alt="" srcset="" />
      <img src="@/assets/use/3.png" alt="" srcset="" />
      <img src="@/assets/use/4.png" alt="" srcset="" />
      <img src="@/assets/use/5.png" alt="" srcset="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title_list: ["web作成使り方", "アプリ作成使り方"],
      title_select: 0,
    };
  },

  methods: {
    select_title_changed(index) {
      this.title_select = index;
    },
  },
};
</script>

<style lang="less" scoped>
.use-container {
  padding-top: 78px;
  .seg {
    background-color: #f2fcfb;
    height: 90px;
    position: relative;
    ul {
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      list-style: none;
      li {
        font-size: 26px;
        margin: 0 70px;
        color: #0c0c0c;
        padding: 16px 0;
        cursor: pointer;
      }

      .select {
        font-size: 26px;
        color: #00baab;
        border-bottom: 4px solid #00baab;
      }
    }
  }

  .content {
    img {
      width: 100%;
    }
  }
}
</style>