<template>
  <!-- 文章 -->
  <div class="article-list">
    <div class="article-title">
      <div class="line"></div>
      <p>「人気記事」</p>
    </div>
    <div class="article">
      <div
        class="article-detail"
        v-for="item in article_list"
        :key="item.article_id"
        @click="toDetail(item.article_path)"
      >
        <img :src="item.cover" :alt="item.cover_alt" srcset="" />
        <span
          v-for="(type, index) in item.article_tags"
          :key="index"
          class="tags"
        >
          {{ type }}
        </span>
        <p class="title">{{ item.article_title }}</p>

        <div class="others">
          <span class="date">
            <img src="@/assets/home/date.png" alt="" />
            <span>{{ item.publish_time }}</span>
          </span>

          <span class="see">
            <img src="@/assets/home/see.png" alt="" />
            <span>{{ item.pv }}</span>
          </span>
        </div>
      </div>
    </div>
    <div class="more" @click="toResource">
      <p>その他の記事</p>
      <img src="@/assets/home/right_arrow.png" alt="" srcset="" />
    </div>
  </div>
</template>
<script>
import { get_article_list } from "@/api/article-list/article-list.js";
export default {
  name: "articlelist",
  data() {
    return {
      article_list: [],
    };
  },

  mounted() {
    this.getArticleList();
  },

  methods: {
    toDetail(path) {
      this.$router.push({ name: "articledetail", params: { path: path } });
    },

    async getArticleList() {
      const res = await get_article_list();
      if (res.code === 200) {
        this.article_list = res.data;
      }
      if (res.code === 10001 || res.code === 10002) {
        await this.getArticleList();
      }
    },

    toResource() {
      this.$router.push({ name: "resource" });
    },
  },
};
</script>

<style lang="less" scoped>
.article-list {
  padding: 20px;
  background-color: #fff;
  .article-title {
    font-weight: 500;
    text-align: center;
    font-size: 40px;
    margin-top: 60px;
    margin-bottom: 50px;
    position: relative;
    .line {
      position: absolute;
      bottom: 5px;
      height: 10px;
      width: 220px;
      left: 590px;
      background-color: #5af0e4;
    }
    p {
      position: relative;
    }
  }
  .article {
    display: flex;
    justify-content: space-around;
    margin: 0 110px;
    .article-detail {
      width: 370px;
      img {
        width: 100%;
        margin-bottom: 30px;
      }

      // 类别
      .tags {
        display: inline-block;
        color: #199389;
        margin: 0;
        margin-right: 10px;
        font-size: 15px;
        font-weight: bold;
      }

      // 文章标题
      .title {
        font-size: 24px;
        color: #212b36;
        font-weight: 500;
        margin-top: 20px;
        overflow: hidden; //超出限定的宽度就隐藏内容
        text-overflow: ellipsis; //当文本溢出时显示省略符号来代表被修剪的文本
        -webkit-line-clamp: 2; //用来限制在一个块元素显示的文本的行数,2表示最多显示2行。 为了实现该效果，它需要组合其他的WebKit属性
        // white-space: pre-wrap;//允许换行 同时保留文本中的空白、换行符；
        display: -webkit-box; //和white-space: pre-wrap;结合使用，将对象作为弹性伸缩盒子模型显示
        -webkit-box-orient: vertical; //和white-space: pre-wrap;结合使用 ，设置或检索伸缩盒对象的子元素的排列方式
      }

      .others {
        margin-bottom: 30px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        height: 30px;
        .date,
        .see {
          margin-right: 30px;
          display: flex;
          span {
            display: inline-block;
            margin-left: 5px;
            font-size: 14px;
            height: 16px;
            line-height: 16px;
          }

          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }

  .more {
    margin-left: 50%;
    transform: translateX(-50%);
    height: 90px;
    width: 280px;
    font-size: 18px;
    font-weight: 600;
    background-color: #f9f9f9;
    display: flex;
    margin-top: 50px;
    margin-bottom: 50px;
    justify-content: space-around;
    img {
      width: 50px;
      align-self: center;
    }

    p {
      height: 90px;
      line-height: 90px;
    }
  }
}
</style>