<template>
  <div class="price-container">
    <div class="seg">
      <ul>
        <li
          v-for="(item, index) in title_list"
          :class="title_select === index ? 'select' : 'unselect'"
          @click="select_title_changed(index)"
        >
          {{ item }}
        </li>
      </ul>
    </div>

    <div class="content">
      <img v-show="title_select == 0" src="@/assets/price/web.png" />
      <router-link to="speclist">
        <img v-show="title_select == 1" src="@/assets/price/price2.png" />
      </router-link>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      title_list: ["web作成値段", "アプリ作成値段"],
      title_select: 0,
    };
  },
  methods: {
    select_title_changed(index) {
      this.title_select = index;
    },
  },
};
</script>

<style lang="less" scoped>
.price-container {
  padding-top: 78px;
  .seg {
    background-color: #f2fcfb;
    height: 90px;
    position: relative;
    ul {
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      list-style: none;
      li {
        font-size: 26px;
        margin: 0 70px;
        color: #0c0c0c;
        padding: 16px 0;
        cursor: pointer;
      }

      .select {
        font-size: 26px;
        color: #00baab;
        border-bottom: 4px solid #00baab;
      }
    }
  }

  .content {
     img {
      margin-top: 0px;
      padding: 0;
      width: 100%;
    }
  }
}
</style>