<template>
  <div class="app-container">
    <steps class="steps" :current="3"></steps>
    <p class="tips">お好きな写真をお選びください</p>
    <div class="edit-area">
      <div class="choice">
        <div class="bg">
          <img class="icon" src="@/assets/edit/bg.png" alt="" srcset="" />
          <span class="title">背景</span>
          <div class="bg-colors-data">
            <ul class="content">
              <li
                v-for="(item, index) in bg_colors"
                :key="index"
                :style="{
                  outline:
                    index == bg_select_index ? '1.5px #5DEFE0 solid' : '',
                  background:
                    'linear-gradient(180deg,#' +
                    item.start_color.toString(16) +
                    ',#' +
                    item.end_color.toString(16) +
                    ')',
                }"
                class="bg-color"
                @click="selectColor(index)"
              ></li>
            </ul>
          </div>
        </div>
        <div v-loading="single_loading" class="meiyan">
          <img class="icon" src="@/assets/edit/make.png" alt="" srcset="" />
          <span class="title">美顔</span>
          <div class="meiyan-data">
            <ul class="content">
              <li
                v-for="(item, index) in meiyan_list"
                :key="index"
                class="meiyan-item"
                @click="select_meiyan_index(index)"
              >
                <el-image
                  :style="{
                    outline:
                      item.isSelect === true ? '1.5px #5DEFE0 solid' : '',
                  }"
                  :src="item.url"
                  fit="scale-down"
                  class="meiyan-img"
                />
              </li>
            </ul>
          </div>
        </div>
        <div v-loading="single_loading" class="clothes-container">
          <img class="icon" src="@/assets/edit/clothes.png" alt="" srcset="" />
          <span class="title">スーツの着替</span>

          <div class="sex">
            <div
              v-for="(item, index) in clothes_list"
              :class="
                sex_selected_index === index ? 'select_options' : 'options'
              "
              @click="sex_selected_changed(index)"
            >
              <span>{{ item.category }}</span>
            </div>
          </div>

          <div class="clothes-data">
            <ul class="content">
              <li
                v-if="clothes_list.length > 0"
                v-for="(item, index) in clothes_list[sex_selected_index]
                  .clothes"
                :key="index"
                :style="{
                  outline:
                    sex_selected_index === clothes_select_index[0] &&
                    index === clothes_select_index[1]
                      ? '1.5px #5DEFE0 solid'
                      : '',
                }"
                @click="clothes_select_changed(index)"
              >
                <el-image :src="item.clothes_url" fit="contain" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="result">
        <div class="photo">
          <div class="single-photo-result">
            <el-image
              :src="result_wm"
              fit="contain"
              v-loading="single_loading"
              v-if="bg_colors.length > 0"
              class="el-image"
              :style="{
                background:
                  'linear-gradient(180deg,#' +
                  bg_colors[bg_select_index].start_color.toString(16) +
                  ',#' +
                  bg_colors[bg_select_index].end_color.toString(16) +
                  ')',
              }"
            />
          </div>
        </div>

        <el-button
          color="#000"
          class="next"
          @click="printTypesetting"
          :disabled="single_loading ? true : false"
        >
          保存
        </el-button>
      </div>
    </div>
    <el-dialog v-model="visible" center class="pay-info">
      <p class="price">
        <span style="font-size: 20px">￥</span>
        <span style="font-size: 28px">480</span>
      </p>
      <div class="photo-result">
        <div class="single">
          <p class="spec-name">写真を保存する</p>
          <div class="single-img">
            <el-image
              :style="{
                background:
                  'linear-gradient(180deg,#' +
                  bg_colors[bg_select_index].start_color.toString(16) +
                  ',#' +
                  bg_colors[bg_select_index].end_color.toString(16) +
                  ')',
              }"
              class="el-image"
              :src="result_wm"
              fit="contain"
            />
          </div>
          <p class="spec-size">
            {{ this.$route.query.width }}mm x {{ this.$route.query.height }}mm
          </p>
        </div>

        <div class="line"></div>

        <div class="typeset">
          <p class="typeset-name">超高品質の図版</p>
          <div class="typeset-img">
            <el-image
              class="el-image"
              :src="print_details[print_details_index].url"
              fit="contain"
            />
          </div>

          <el-select
            v-model="typesetting_select_value"
            class="m-2 select"
            :placeholder="
              print_details[print_details_index].name +
              ' ' +
              print_details[print_details_index].photopaper_width_mm +
              'mm x ' +
              print_details[print_details_index].photopaper_height_mm +
              'mm'
            "
            size="large"
            @change="selectChanged"
          >
            <el-option
              v-for="(item, index) in print_details"
              :key="item.name"
              :label="
                item.name +
                ' ' +
                item.photopaper_width_mm +
                'mm x ' +
                item.photopaper_height_mm +
                'mm'
              "
              :value="index"
            />
          </el-select>
        </div>
      </div>

      <div id="paypal-button-container"></div>
      <p class="payinfo-tips">
        支払いに関する問題については、お問い合わせ先のメールアドレス：jpphoto.service@leqi.ai
        までご連絡ください。
      </p>
    </el-dialog>
    <article-list></article-list>
    <user-evaluation></user-evaluation>
  </div>
</template>

<script>
import Steps from "@/components/Steps.vue";
import { get_bg_color, get_clothes } from "@/api/edit/get_edit_data.js";
import {
  print_typesetting,
  makeOrder,
  downloadPics,
} from "@/api/print/printTypesetting.js";
import { make_img } from "@/api/upload_img/uploadImg.js";
import { loadScript } from "@paypal/paypal-js";
import requests from "@/api/axios";
import { ElLoading, ElMessage } from "element-plus";
import ArticleList from "@/components/Articles.vue";
import UserEvaluation from "@/components/UserEvaluation.vue";
import { event } from "vue-gtag";
var paypal;

export default {
  components: { Steps, ArticleList, UserEvaluation },
  name: "edit",
  data() {
    return {
      bg_colors: [], //背景色列表
      meiyan_list: [
        { isSelect: false, url: require("@/assets/edit/make_no.png") },
        { isSelect: true, url: require("@/assets/edit/make_yes.png") },
      ], //是否美颜

      clothes_list: [], //服装列表
      bg_select_index: 2, //选中第几个颜色
      sex_selected_index: 0, //选中的服装类型，男装，女装，子供服
      clothes_select_index: [0, 0], //选中哪个服装

      is_beauty: true, //是否美颜
      clothes_key: null, //服装的key
      result_wm: null, //制作的结果图
      result_key: null, //制作结果图的key

      print_details: [], //打印排版图的结果数据
      print_details_index: 0, //选中的排版图
      typesetting_select_value: "",
      cache_results_url: [], //每次制作完的缓存图
      clientId: {
        sandbox:
          "AW46F3oWG_4P1H_j_EVI18-uiP5cR2kj0bsnav4HZAumZuPpwLSEwDvD0b0QZAfHO3ju7NQWNeH_l9vO",
        production:
          "AbIew6LwPU5fjWYjndtaEwPmR_rjc8hEGZiFnvktfuSyZxFvbPhqf6EyJ3uxTiMYzFvqncnoU2Gtu5J9",
      },
      visible: false, //点击保存后的支付弹框
      order_no: "",
      single_loading: false, //单人图加载状态
      loading: null, //全屏加载框
    };
  },

  async mounted() {
    event("pc-进入编辑页");
    await this.getColors();
    await this.getClothes();
    this.result_wm = this.$route.query.result_wm;
    this.result_key = this.$route.query.result_key;
  },

  methods: {
    //支付页选择了打印排版图
    selectChanged(index) {
      this.print_details_index = index;
    },

    clothes_select_changed(index) {
      this.clothes_select_index[0] = this.sex_selected_index;
      this.clothes_select_index[1] = index;
      this.clothes_key =
        this.clothes_list[this.clothes_select_index[0]].clothes[
          this.clothes_select_index[1]
        ].clothes_key;
      this.make();
    },

    // 生成订单
    async make_order() {
      event("pc-编辑页-创建订单");
      const data = {
        key: this.$route.query.img_key,
        result_key: this.result_key,
        single_key: this.result_list.single_keys.single_key,
        print_key:
          this.result_list.print_details[this.print_details_index].print_key,
        color_name: this.bg_colors[this.bg_select_index].color_name,
        size: this.result_list.print_details[this.print_details_index].name,
        spec_id: this.$route.query.spec_id,
        is_beauty: this.is_beauty,
        clothes_key: this.clothes_key,
      };
      const res = await makeOrder(data);
      if (res.code === 200) {
        this.order_no = res.data.order_no;
        return true;
      } else {
        ElMessage({ message: res.msg, type: "error" });
      }

      if (res.code === 10001 || res.code === 10002) {
        this.make_order();
      }
      return false;
    },

    //支付完成到保存页面
    async download_pics() {
      event("pc-编辑页-支付成功");
      const res = await downloadPics(this.order_no);
      if (res.code === 200) {
        this.$router.replace({
          name: "save",
          query: {
            print_url: res.data.print_url,
            single_url: res.data.single_url,
            width: this.$route.query.width,
            height: this.$route.query.height,
            type_width:
              this.print_details[this.print_details_index].photopaper_width_mm,
            type_height:
              this.print_details[this.print_details_index].photopaper_height_mm,
            size: this.print_details[this.print_details_index].name,
            order_no: this.order_no,
          },
        });
      } else {
        ElMessage({ message: res.msg, type: "error" });
      }

      if (res.code === 10001 || res.code === 10002) {
        this.download_pics();
      }
    },

    // 调用paypal支付
    async pay_with_paypal() {
      const that = this;
      if (paypal == null) {
        try {
          paypal = await loadScript({
            clientId: that.clientId.production,
            currency: "JPY",
          });
        } catch (error) {
          // console.log(error, "error with paypal");
          ElMessage({
            message: "ネットワークが不安定なので、リトライをお願いします。",
            type: "error",
          });
        }

        if (paypal) {
          try {
            await paypal
              .Buttons({
                async createOrder() {
                  const makeOrderRes = await that.make_order();
                  if (!makeOrderRes) {
                    return;
                  }
                  const data = {
                    order_no: that.order_no,
                  };
                  const res = await requests.post("web/payment/paypal", data);
                  if (res.code === 200) {
                    return res.data.order_id;
                  } else {
                    ElMessage({ message: res.msg, type: "error" });
                  }
                },

                async onApprove(data) {
                  const res = await requests.post(
                    `web/order/${data.orderID}/capture`
                  );
                  if (res.code === 200) {
                    that.download_pics();
                  } else {
                    ElMessage({ message: res.msg, type: "error" });
                  }
                  // return console.log(res, "onApprove");
                },
              })
              .render("#paypal-button-container");
          } catch (error) {
            // console.log(error, "paypal button");
            ElMessage({
              message: "ネットワークが不安定なので、リトライをお願いします。",
              type: "error",
            });
          }
        }
      }
    },

    // 打印排版
    async printTypesetting() {
      event("pc-编辑页-点击保存");
      if (this.single_loading) {
        return ElMessage({
          message: "写真処理中です。もう少々お待ちください。",
          type: "error",
        });
      }
      this.loading = ElLoading.service({ text: "Loading..." });

      const res = await print_typesetting(
        this.$route.query.spec_id,
        this.bg_colors[this.bg_select_index].start_color,
        this.bg_colors[this.bg_select_index].end_color,
        this.result_key
      );
      this.loading.close();
      if (res.code === 200) {
        this.visible = true;
        this.print_details = res.data.print_details;
        this.result_list = res.data;
        this.typesetting_loading = false;
        this.pay_with_paypal();
      } else {
        ElMessage({ message: res.msg, type: "error" });
      }

      if (res.code === 10001 || res.code === 10002) {
        this.printTypesetting();
      }
    },

    sex_selected_changed(index) {
      this.sex_selected_index = index;
    },

    select_meiyan_index(current_index) {
      for (let index = 0; index < this.meiyan_list.length; index++) {
        this.meiyan_list[index].isSelect = false;
        if (index === current_index) {
          this.meiyan_list[index].isSelect = true;
        }
      }

      this.is_beauty = current_index === 1 ? true : false;
      this.make();
    },
    // 获取背景色数据
    async getColors() {
      const res = await get_bg_color();
      if (res.code === 200) {
        this.bg_colors = res.data.colors;
      }
      if (res.code === 10001 || res.code === 10002) {
        this.getColors();
      }
    },

    // 获取服装图数据
    async getClothes() {
      const res = await get_clothes();
      if (res.code === 200) {
        for (let i = 0; i < res.data.length; i++) {
          var sex_clothes_list = res.data[i];
          sex_clothes_list.clothes.unshift({
            clothes_key: null,
            clothes_url: require("@/assets/edit/make_no.png"),
          });
        }
        this.clothes_list = res.data;
        // 缓存制作过的图
        // this.cache_results_url = res.data;
        // for (let i = 0; i < this.cache_results_url.length; i++) {
        //   const element_i = this.cache_results_url[i];
        //   for (let j = 0; j < element_i.clothes.length; j++) {
        //     const element_j = element_i.clothes[j];
        //     element_j["cache_url"] = "";
        //     element_j["cache_url_no"] = "";
        //     element_j["result_key"] = "";
        //     element_j["result_key_no"] = "";
        //     if (element_j.clothes_key == null) {
        //       element_j["cache_url"] = this.result_wm;
        //       element_j["result_key"] = this.$route.query.result_key;
        //     }
        //   }
        // }
      }
      if (res.code === 10001 || res.code === 10002) {
        this.getClothes();
      }
    },

    // 先做缓存再开始制作
    make() {
      //将获取的数据缓存到数组中
      // for (let i = 0; i < this.cache_results_url.length; i++) {
      //   const element_i = this.cache_results_url[i];
      //   for (let j = 0; j < element_i.clothes.length; j++) {
      //     const element_j = element_i.clothes[j];
      //     if (this.clothes_key === element_j.clothes_key) {
      //       if (this.is_beauty == true) {
      //         if (element_j.result_key.length != 0) {
      //           this.result_wm = element_j.cache_url;
      //           this.result_key = element_j.result_key;
      //           return;
      //         }
      //       } else {
      //         if (element_j.result_key_no.length != 0) {
      //           this.result_wm = element_j.cache_url_no;
      //           this.result_key = element_j.result_key_no;
      //           return;
      //         }
      //       }
      //     }
      //   }
      // }
      //开始制作图片
      this.makeImg();
    },

    // 制作图片
    async makeImg() {
      this.single_loading = true;
      const res = await make_img(
        this.$route.query.img_key,
        this.$route.query.spec_id,
        this.is_beauty,
        this.clothes_key
      );
      this.single_loading = false;
      if (res.code === 200) {
        this.result_wm = res.data.result_wm;
        this.result_key = res.data.result_key;
        //将获取的数据缓存到数组中
        // for (let i = 0; i < this.cache_results_url.length; i++) {
        //   const element_i = this.cache_results_url[i];
        //   for (let j = 0; j < element_i.clothes.length; j++) {
        //     const element_j = element_i.clothes[j];
        //     if (element_j.clothes_key === this.clothes_key) {
        //       if (this.is_beauty == true) {
        //         element_j.cache_url = res.data.result_wm;
        //         element_j.result_key = res.data.result_key;
        //       } else {
        //         element_j.cache_url_no = res.data.result_wm;
        //         element_j.result_key_no = res.data.result_key;
        //       }
        //     }
        //   }
        // }
      }

      if (res.code === 10001 || res.code === 10002) {
        this.makeImg();
      }
    },

    // 选中背景色
    selectColor(index) {
      this.bg_select_index = index;
      this.typesetting_loading = true;
    },
  },
};
</script>

<style lang="less" scoped>
.app-container {
  padding-top: 78px;
  background-color: #f6f8fc;
  .steps {
    margin-top: 40px;
  }

  .tips {
    color: #333;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }

  .edit-area {
    margin: 24px 150px auto 150px;
    display: flex;
    justify-content: space-between;
    .choice {
      width: 250px;
      .bg {
        width: 100%;
        height: 110px;
        background-color: #fff;
        border-radius: 12px;
        border: 1px #eceffb solid;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
        .bg-colors-data {
          margin: 10px 10px 0 10px;
          height: 40px;
          // background-color: blue;
          .content {
            display: flex;
            list-style: none;
            align-items: center;
            height: 100%;
            li {
              width: 30px;
              height: 30px;
              margin-left: 12px;
              border-radius: 4px;
              border: 1px solid #f0f0f0;
              cursor: pointer;
            }
          }
        }
      }

      .meiyan {
        width: 100%;
        height: 125px;
        background-color: #fff;
        border-radius: 12px;
        border: 1px #eceffb solid;
        margin-top: 20px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
        .meiyan-data {
          margin: 10px 10px 0 10px;
          height: 60px;
          padding: 0;
          .content {
            display: flex;
            list-style: none;
            align-items: center;
            height: 100%;
            li {
              width: 50px;
              margin-right: 12px;
              margin-left: 0;
              border-radius: 4px;
              cursor: pointer;
              .meiyan-img {
                width: 100%;
                height: 100%;
                margin: 0;
                border-radius: 4px;
              }
            }
          }
        }
      }

      .clothes-container {
        width: 100%;
        height: 290px;
        background-color: #fff;
        border-radius: 12px;
        border: 1px #eceffb solid;
        margin-top: 20px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
        .sex {
          background-color: #eeeeee;
          border-radius: 4px;
          width: 156px;
          height: 24px;
          margin: 0 auto;
          margin-top: 15px;
          display: flex;
          justify-content: space-around;
          .select_options {
            line-height: 24px;
            align-items: center;
            font-size: 12px;
            width: 55px;
            text-align: center;
            color: #000;
            background-color: #fff;
            border: 1px solid #e0e0e0;
            border-radius: 4px;
            cursor: pointer;
          }
          .options {
            line-height: 24px;
            align-items: center;
            font-size: 12px;
            width: 55px;
            text-align: center;
            color: #666;
            border: 1px solid transparent;
            cursor: pointer;
          }
        }
        .clothes-data {
          margin-top: 10px;
          .content {
            list-style: none;
            height: 100%;
            margin: 0 14px;
            li {
              float: left;
              width: 40px;
              height: 50px;
              margin-right: 12px;
              margin-left: 0;
              border-radius: 4px;
              margin: 6px 5px;
              background-color: #eaedf2;
              display: flex;
              cursor: pointer;
              img {
                width: 100%;
                margin: 0;
              }
            }
          }
        }
      }

      .bg,
      .meiyan,
      .clothes-container {
        padding-top: 12px;
        img {
          width: 20px;
          height: 20px;
          margin-left: 14px;
          margin-top: 0px;
        }

        .title {
          margin-top: 0px;
          color: #666;
          font-size: 14px;
          display: inline-block;
          margin-left: 12px;
        }
      }
    }
    .result {
      display: grid;
      margin-bottom: 100px;
      .photo {
        width: 825px;
        // padding: 16px;
        border: 1.5px dashed #5defe0;
        background-color: #fff;
        // display: flex;
        // justify-content: space-around;
        // align-items: center;
        // margin: 0 auto;
        .single-photo-result {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          .el-image {
            width: 300px;
          }
        }
      }
      .next {
        display: inline-block;
        margin: 44px auto 0;
        cursor: pointer;
        box-shadow: #5af0e4 4px 4px;
        border-radius: 10px;
        padding: 0;
        width: 260px;
        height: 46px;
        line-height: 46px;
      }
    }
  }

  .pay-info {
    .price {
      text-align: center;
      color: #e23e3e;
    }
    .photo-result {
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 40px;
      p {
        text-align: center;
        font-size: 12px;
        color: #0c0c0c;
        display: inline-block;
        width: 257px;
        margin: 0;
        height: 30px;
      }

      .line {
        width: 1px;
        background-color: #dcdcdc;
        margin-top: 20px;
        margin-bottom: 50px;
      }

      .single,
      .typeset {
        text-align: center;
        .single-img,
        .typeset-img {
          .el-image {
            width: 200px;
          }
        }
      }
    }
    #paypal-button-container {
      width: 500px;
      margin: 0 auto;
    }
    .payinfo-tips {
      margin: 15px 50px;
      color: #666;
      font-size: 16px;
      text-align: center;
    }
  }
}
</style>