<template>
  <div class="app-container">
    <div class="menu-space">
      <div v-if="menu_list.length > 0" class="article-menu">
        <div class="vhtml-menu">
          <img src="@/assets/article-detail/menu.png" alt="" srcset="" />
          <span>目次</span>
        </div>
        <div class="menu-content">
          <ul>
            <li
              v-for="(item, index) in menu_list"
              @click="scrollToPosition(item.id)"
            >
              {{ index + 1 }}. {{ item.content }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="scroll-area">
      <div class="article-detail">
        <div v-html="mainHTML" id="vhtml"></div>
      </div>
      <el-backtop :bottom="180" :visibility-height="400"> </el-backtop>
      <div class="article-list">
        <ul>
          <li
            v-for="(item, index) in article_list"
            :key="item.article_id"
            @click="toArticleDetail(item.article_path)"
          >
            <img class="cover" :src="item.cover" alt="" srcset="" />
            <span
              class="tag"
              v-for="(tag, tag_index) in item.article_tags"
              :key="tag_index"
            >
              {{ tag }}
            </span>
            <p class="article-title">
              {{ item.article_title }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import requests from "@/api/axios";
import { get_article_list } from "@/api/article-list/article-list.js";

export default {
  props: ["id"],
  name: "articledetail",
  data() {
    return {
      mainHTML: "", //文章内容
      menu_list: [], //索引列表
      article_list: [], //文章列表
    };
  },

  methods: {
    scrollToPosition(id) {
      document.getElementById(id).scrollIntoView();
    },

    toArticleDetail(path) {
      location.pathname = "/resource/" + path;
    },
  },

  async mounted() {
    //声明方法
    window.scrollToPosition = this.scrollToPosition;

    const res = await requests.request({
      url: "web/article/" + this.$route.params.path,
      method: "get",
      params: {
        origin: "pc",
      },
    });
    if (res.code === 200) {
      this.mainHTML = res.data.content;
      this.$nextTick(() => {
        //h2标签添加id
        setTimeout(() => {
          const h2s = document.getElementsByTagName("h2");
          var temp = [];
          for (let index = 0; index < h2s.length; index++) {
            const element = h2s[index];
            element.id = "id" + index;
            temp.push({
              id: element.id,
              content: element.innerText,
            });
          }
          this.menu_list = temp;

          var imgs = document
            .getElementById("vhtml")
            .getElementsByTagName("img");
          for (let index = 0; index < imgs.length; index++) {
            var element = imgs[index];
            const img_href = element.getAttribute("data-href");
            //图片链接跳转
            if (img_href.length !== 0) {
              element.parentNode.innerHTML = `<a href="${img_href}"><img src="${element.src}" alt="${element.alt}"></a>`;
            }
          }
        }, 500);
      });
    }

    const article_list_res = await get_article_list();
    if (article_list_res.code === 200) {
      this.article_list = article_list_res.data;
      this.loading = false;
    }
  },
};
</script>

<style lang="less" scoped>
.app-container {
  padding-top: 78px;

  .menu-space {
    width: 180px;
    margin-left: 58px;
    margin-top: 120px;
    position: absolute;
    background-color: #fff;

    //  隐藏滚动条
    /*兼容火狐方案（网上传的overflow:-moz-scrollbars-none;方案亲测无效）*/
    scrollbar-width: none;
    /*兼容IE*/
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      /*兼容谷歌*/
      display: none;
    }

    .article-menu {
      border: 1px solid #d2d2d2;
      width: 100%;
      padding: 15px;
      .vhtml-menu {
        display: flex;
        align-items: center;
        img {
          width: 15px;
          height: 15px;
        }

        span {
          font-size: 26px;
          margin-left: 5px;
        }
      }
      .menu-content {
        overflow-y: scroll; //滚动
        max-height: 600px; //设置最大高度，超出会滚动
        cursor: pointer;
        ul {
          list-style: none;
          li {
            margin: 15px 0;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }
  }

  .scroll-area {
    display: flex;
    justify-content: space-around;
    margin-left: 240px;
    .article-detail {
      width: 900px;
    }

    .article-list {
      width: 200px;
      margin-top: 120px;
      ul {
        list-style: none;
        li {
          cursor: pointer;
          margin-bottom: 40px;
          .cover {
            width: 100%;
            border-radius: 4px;
            margin-bottom: 10px;
          }

          .tag {
            display: inline-block;
            border: 0.8px solid #199389;
            color: #199389;
            font-size: 10px;
            height: 16px;
            line-height: 16px;
            border-radius: 7px;
            padding: 0 10px;
            margin-right: 5px;
          }

          .article-title {
            margin-top: 8px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
