<template>
  <div class="save-container">
    <steps class="steps" :current="4"></steps>

    <div class="result">
      <div class="photo-result">
        <div class="single">
          <p class="spec-name">写真を保存する</p>
          <div class="single-img">
            <el-image class="el-image" :src="this.$route.query.single_url" />
          </div>
          <p class="spec-size">
            {{ this.$route.query.width }}mm x {{ this.$route.query.height }}mm
          </p>
        </div>

        <div class="line"></div>

        <div class="typeset">
          <p class="typeset-name">超高品質の図版</p>
          <div class="typeset-img">
            <el-image class="el-image" :src="this.$route.query.print_url" />
          </div>
          <p class="typeset-size">
            {{ this.$route.query.size }}（{{ this.$route.query.type_width }}mm x
            {{ this.$route.query.type_height }}mm）
          </p>
        </div>
      </div>

      <el-button color="#333" class="download-photo" @click="download_pic">
        ダウンロードし
      </el-button>
    </div>

    <div class="email">
      <p class="tips">メールで送ってくれます：</p>
      <div class="input-group mb-3 input-search">
        <input
          type="text"
          class="form-control"
          placeholder="メールをお願いします"
          aria-label="メールをお願いします"
          aria-describedby="basic-addon2"
          v-model="email"
        />
        <div class="input-group-append">
          <button
            type="button"
            class="btn btn-dark"
            @click="valid_email"
            v-loading="send_email_status"
          >
            確定
          </button>
        </div>
      </div>
    </div>

    <el-button class="to-home" @click="toHomepage">
      トップページに戻り
    </el-button>
    <article-list></article-list>
    <user-evaluation></user-evaluation>
  </div>
</template>

<script>
import Steps from "@/components/Steps.vue";
import { ElMessage } from "element-plus";
import { sendEmail } from "@/api/email/email.js";
import ArticleList from "@/components/Articles.vue";
import UserEvaluation from "@/components/UserEvaluation.vue";
import { event } from "vue-gtag";

export default {
  components: { Steps, ArticleList, UserEvaluation },
  name: "save",
  data() {
    return {
      email: "",
      send_email_status: false,
      pic_name: 0,
    };
  },
  methods: {
    // 下载图片
    download_pic() {
      event("pc-下载");
      this.downloadIamge(
        this.$route.query.print_url,
        this.$route.query.single_url
      );
    },

    // 返回首页
    toHomepage() {
      this.$router.push({ name: "home" });
    },

    downloadIamge(print_url, single_url) {
      this.pic_name += 1;
      var timestamp = new Date().getTime();
      //下载图片地址和图片名
      var image = new Image();
      const that = this;
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/jpg"); //得到图片的base64编码数据
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "single_" + that.pic_name + timestamp; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = single_url;

      setTimeout(() => {
        //下载图片地址和图片名
        var image1 = new Image();
        // 解决跨域 Canvas 污染问题
        image1.setAttribute("crossOrigin", "anonymous");
        image1.onload = function () {
          var canvas = document.createElement("canvas");
          canvas.width = image1.width;
          canvas.height = image1.height;
          var context = canvas.getContext("2d");
          context.drawImage(image1, 0, 0, image1.width, image1.height);
          var url = canvas.toDataURL("image/jpg"); //得到图片的base64编码数据
          var a = document.createElement("a"); // 生成一个a元素
          var event = new MouseEvent("click"); // 创建一个单击事件
          a.download = "print_" + that.pic_name + timestamp; // 设置图片名称
          a.href = url; // 将生成的URL设置为a.href属性
          a.dispatchEvent(event); // 触发a的单击事件
        };
        image1.src = print_url;
      }, 300);
    },
    // a
    valid_email() {
      //Email正则
      var ePattern = /^([\w\.-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])/;
      if (ePattern.test(this.email) === false) {
        return ElMessage({
          message: "メールのフォーマットではありません",
          type: "error",
        });
      }
      this.send_email();
    },

    // 发送邮箱 ran.ni@leqi.ai
    async send_email() {
      this.send_email_status = true;
      const data = {
        order_no: this.$route.query.order_no,
        email: this.email,
      };
      const res = await sendEmail(data);
      this.send_email_status = false;

      if (res.code === 200) {
        this.email = "";
        return ElMessage({
          message: res.msg,
          type: "success",
        });
      } else {
        ElMessage({
          message: res.msg,
          type: "error",
        });
      }

      if (res.code === 10001 || res.code === 10002) {
        this.send_email();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.save-container {
  padding-top: 78px;
  background-color: #f6f8fc;
  display: grid;
  .steps {
    margin-top: 40px;
  }

  .result {
    width: 680px;
    height: 500px;
    background-color: #fff;
    margin: 10px auto;
    border-radius: 20px;
    display: grid;
    .photo-result {
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 2px;
      padding: 20px 0;
      // background-color: red;
      .single {
        p {
          text-align: center;
          font-size: 20px;
          color: #0c0c0c;
          width: 200px;
          margin: 0;
        }
        .single-img {
          width: 200px;
          margin: 10px 0;
          .el-image {
            height: 100%;
          }
        }
        .spec-size {
          font-size: 12px;
        }
      }
      .line {
        width: 1px;
        background-color: #dcdcdc;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .typeset {
        p {
          text-align: center;
          font-size: 20px;
          color: #0c0c0c;
          width: 200px;
          margin: 0;
        }
        .typeset-img {
          width: 200px;
          margin: 10px 0;
          .el-image {
            width: 100%;
          }
        }

        .typeset-size {
          font-size: 12px;
        }
      }
    }

    .download-photo {
      margin: 0 auto;
      box-shadow: #5af0e4 4px 4px;
      width: 260px;
      height: 46px;
      line-height: 46px;
      font-size: 14px;
    }
  }

  .email {
    width: 680px;
    height: 150px;
    background-color: #fff;
    margin: 0 auto;
    border-radius: 20px;
    .tips {
      color: #0c0c0c;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      padding-top: 28px;
    }

    .input-search {
      width: 340px;
      margin-left: 50%;
      transform: translateX(-50%);
      .form-control {
        font-size: 12px;
        height: 40px;
      }

      .btn-dark {
        font-size: 14px;
        height: 40px;
        width: 80px;
        white-space: nowrap;
        margin: 0;
        padding: 0;
      }
    }
  }

  .to-home {
    text-align: center;
    margin: 50px auto 70px;
    padding: 15px 100px;
    border-radius: 10px;
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    background-color: #0c0c0c;
    height: 40px;
    line-height: 40px;
  }
}
</style>