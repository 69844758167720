import requests from "@/api/axios";
import axios from "axios";

export async function get_img_url(filename, file_type) {
    return await requests.post("web/image/url", {
        filename: filename,
        file_type: file_type,
    });
}

// url：阿里云地址
// file:图片文件
// type：图片类型
export async function upload_img(url, file, type) {
    if (file) {
        const res = await axios.put(url, file, { headers: { 'Content-Type': type } });
        return res;
    }
}

// 开始制作流程
export async function make_img(key, spec_id, is_beauty, clothes_key) {
    const data = {
        key: key,
        spec_id: spec_id,
        is_beauty: is_beauty,
        clothes_key: clothes_key
    };
    return await requests.post('web/cutout', data);
}


