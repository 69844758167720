<template>
  <div class="header">
    <div class="logo">
      <router-link to="/">
        <img src="@/assets/home/logo_title.png" />
      </router-link>
    </div>

    <div class="indexes">
      <router-link
        v-for="(item, index) in navData"
        :to="item.title"
        @click.native="selectItem(index)"
        active-class="active"
        :class="'index'"
        style="text-decoration: none"
      >
        <div>
          <span>{{ item.name }}</span>
        </div>
      </router-link>

      <div class="download">
        <router-link to="/download">
          <img src="@/assets/header/download.png" alt="" />
        </router-link>
      </div>

      <div class="download">
        <a
          href="https://apps.apple.com/jp/app/id1626934418?l=ja"
          @click="apple"
        >
          <img src="@/assets/header/app_store.png" alt="" />
        </a>
      </div>

      <div class="download">
        <a
          href="https://play.google.com/store/apps/details?id=com.leqi.idphoto"
          @click="google"
        >
          <img src="@/assets/header/google_play.png" alt="" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navData: [
        { title: "/use", name: "使り方" },
        { title: "/price", name: "値段" },
        { title: "/specs", name: "仕様のリスト" },
      ],
    };
  },

  methods: {
    selectItem(index) {
      this.$router.push({ path: this.navData[index].title });
    },

    google() {},

    apple() {},
  },
};
</script>

<style lang="less" scoped>
.header {
  z-index: 2001;
  height: 78px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  .logo {
    // background-color: #fff;
    font-size: 26px;
    margin: auto 0;
    margin-left: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 180px;
      margin-top: 10px;
    }
  }

  .indexes {
    margin-left: 50px;
    display: flex;
    width: 1000px;
    justify-content: space-around;
    align-items: center;

    .download {
      a {
        img {
          width: 110px;
        }
      }
    }

    .index {
      width: 160px;
      height: 78px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 16px;
        color: #666;
        white-space: nowrap;
      }
    }

    .index:hover {
      background-color: #f9f9f9;

      span {
        color: #199389;
        font-weight: 500;
        text-decoration: none;
      }
    }

    .index {
      div {
        span {
          color: #666;
          font-weight: 500;
          text-decoration: none;
        }
      }
    }

    .active {
      background-color: #f9f9f9;

      div {
        span {
          color: #199389;
          font-weight: 500;
        }
      }
    }
  }
}
</style>