<template>
  <div class="app-container">
    <div class="banner">
      <img :src="require('@/assets/specs/banner.png')" alt="" />
    </div>

    <div class="wrapper">
      <ul class="content">
        <li class="categary" v-for="(item, index) in categarys" :key="index">
          <img class="img" :src="item.default_tag" alt="" />
          <p>{{ item.name }}</p>
        </li>
      </ul>
    </div>

    <div class="spec-container" v-for="(item, index) in specs" :key="index">
      <div class="table">
        <div class="specname" :id="item.id">
          <p>{{ item.name }}</p>
        </div>

        <div class="title">
          <p class="spec">规格</p>
          <p class="size">尺寸(mm)</p>
        </div>

        <ul>
          <li v-for="(spec, index) in item.data" @click="selectSpec(spec)">
            <p class="spec">{{ spec.name }}</p>
            <p class="size">{{ spec.width }} x {{ spec.height }}</p>
          </li>
        </ul>
      </div>

      <div class="make">
        <router-link to="/speclist"> 今すぐ証明写真を作成する </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {
  get_spec_list,
  get_spec_category,
} from "@/api/get_spec_list/getSpecList.js";

export default {
  name: "specs",
  data() {
    return {
      categarys: [],
      specs: [],
    };
  },

  async mounted() {
    this.get_categories();
  },

  methods: {
    async getSpecList(words, name) {
      const res = await get_spec_list(words);
      if (res.code === 200) {
        const data = {
          id: words,
          name: name,
          data: res.data,
        };
        this.specs.push(data);
      }
      if (res.code === 10001 || res.code === 10002) {
        this.getSpecList(words);
      }
    },

    async get_categories() {
      const res = await get_spec_category();
      this.categarys = res.data;
      this.specs = [];
      if (res.code === 200) {
        for (let index = 0; index < res.data.length; index++) {
          const data = res.data[index];
          await this.getSpecList(data.id, data.name);
        }
      }

      if (res.code === 10001 || res.code === 10002) {
        this.get_categories();
      }
    },

    choose_specs(id) {
      document.getElementById(id).scrollIntoView();
    },
  },
};
</script>

<style lang="less" scoped>
.app-container {
  padding-top: 60px;
  background: linear-gradient(to bottom, rgba(103, 238, 219, 0.2), #fff);
  .banner {
    img {
      width: 100%;
    }
  }

  .wrapper {
    white-space: nowrap;
    overflow: hidden;
    margin-top: 40px;
    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      .categary {
        border-radius: 24px;
        display: inline-block;
        width: 132px;
        height: 180px;
        margin: 0 25px;
        background-color: #fff;
        text-align: center;

        .img {
          margin-top: 20px;
          width: 84px;
        }
        p {
          margin-top: 10px;
          font-size: 20px;
        }
        box-shadow: 0 2px 8px 0 rgba(41, 148, 139, 0.1);
      }
    }
  }

  .spec-container {
    display: grid;
    .table {
      margin: 0 200px;
      box-shadow: 6px 6px 6px #93adab;
      border: 1px #212b36 solid;
      margin-top: 45px;
      width: 1000px;
      .specname {
        height: 60px;
        line-height: 60px;
        text-align: center;
        font-size: 30px;
      }
      .title {
        height: 60px;
        line-height: 60px;
        display: flex;
        text-align: center;
        font-size: 20px;
        color: #333;
        background-color: #fff;
        .spec {
          width: 50%;
        }

        .size {
          width: 50%;
        }
      }

      > ul {
        background-color: #fff;
        li {
          display: flex;
          text-align: center;
          height: 60px;
          border-top: #c7e5e3 solid 1px;
          margin-left: 15px;
          margin-right: 15px;
          p {
            color: #525a5a;
            font-size: 16px;
            width: 50%;
            align-self: center;
          }
        }
      }
    }

    .make {
      margin: 80px auto 60px;
      a {
        margin-top: 10px;
        background-color: #333;
        padding: 15px 20px;
        border-radius: 12px;
        font-size: 20px;
        color: #fff;
        font-weight: 500;
        box-shadow: #5af0e4 4px 4px;
        text-decoration: none;
      }
    }
  }
}
</style>