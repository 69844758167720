<template>
  <div class="article-container">
    <ul v-loading="loading">
      <li
        class="item"
        v-for="(item, index) in article_list"
        @click="toDetail(item.article_path)"
      >
        <img
          class="cover-img"
          :src="item.cover"
          :alt="item.cover_alt"
          srcset=""
        />

        <span
          class="tags"
          v-for="(type, index) in item.article_tags"
          :key="index"
        >
          {{ type }}
        </span>

        <p class="title">{{ item.article_title }}</p>

        <div class="others">
          <span class="date">
            <img src="@/assets/home/date.png" alt="" />
            <span>{{ item.publish_time }}</span>
          </span>

          <span class="see">
            <img src="@/assets/home/see.png" alt="" />
            <span>{{ item.pv }}</span>
          </span>
        </div>
      </li>
      <el-pagination
        class="pagination"
        hide-on-single-page
        background
        layout="prev, pager, next"
        :total="total"
        v-model:current-page="currentpage"
        @current-change="currentchange"
        v-model:page-size="pagesize"
      />
    </ul>
  </div>
</template>

<script>
import { get_article_list } from "@/api/article-list/article-list.js";
export default {
  name: "resource",
  data() {
    return {
      article_list: [],
      loading: true,
      total: 0,
      currentpage: 1,
      pagesize: 12,
      loading: true,
    };
  },

  mounted() {
    this.currentchange();
  },

  methods: {
    toDetail(path) {
      this.$router.push({ name: "articledetail", params: { path: path } });
    },

    async currentchange() {
      this.loading = true;
      console.log(this.currentpage, "currentpage");
      const res = await get_article_list(this.currentpage, this.pagesize);
      console.log(res, "get_article_list");
      this.loading = false;
      if (res.code === 200) {
        this.total = res.total;
        this.article_list = res.data;
        this.loading = false;
      }
    },
    // prevclick() {
    //   this.currentpage--;
    //         console.log(this.currentpage, "prevclick");

    // },
    // nextclick() {
    //   this.currentpage++;
    //         console.log(this.currentpage, "nextclick");

    // },
  },
};
</script>

<style lang="less" scoped>
.article-container {
  padding: 90px 120px 0 120px;
  ul {
    position: relative;
    .pagination {
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .item {
      display: inline-block;
      width: 370px;
      margin: 20px 10px;
      cursor: pointer;
      .cover-img {
        width: 100%;
        margin-bottom: 15px;
      }

      .tags {
        display: inline-block;
        border: 1px solid #199389;
        height: 24px;
        line-height: 24px;
        border-radius: 12px;
        padding: 0 10px;
        color: #199389;
        margin-right: 10px;
        margin-top: 5px;
        font-size: 12px;
      }

      .title {
        color: #212b36;
        font-weight: 500;
        font-size: 24px;
        margin-top: 12px;

        overflow: hidden; //超出限定的宽度就隐藏内容
        text-overflow: ellipsis; //当文本溢出时显示省略符号来代表被修剪的文本
        -webkit-line-clamp: 2; //用来限制在一个块元素显示的文本的行数,2表示最多显示2行。 为了实现该效果，它需要组合其他的WebKit属性
        white-space: pre-wrap; //允许换行 同时保留文本中的空白、换行符；
        display: -webkit-box; //和white-space: pre-wrap;结合使用，将对象作为弹性伸缩盒子模型显示
        -webkit-box-orient: vertical;
      }

      .others {
        margin-bottom: 30px;
        margin-top: 20px;
        display: flex;

        .date,
        .see {
          margin-right: 30px;
          display: flex;

          span {
            margin-left: 5px;
            font-size: 14px;
            line-height: 16px;
            height: 16px;
            // background-color: red;
          }

          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .item:hover {
      .title {
        color: #199389;
      }
    }
  }
}
</style>
