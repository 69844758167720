<template>
  <!-- 用户评价 -->
  <div class="user-evaluation">
    <div class="user-evaluation-title">
      <div class="line"></div>
      <p>ユーザー評価</p>
    </div>

    <div class="bullet-wrap">
      <div
        class="bullet-item"
        :class="'bullet-animation-' + item.random"
        :data-line="item.line"
        v-for="item in state.bulletlist"
        @animationend="animationend"
        :key="item.id"
      >
        <img :src="item.header" alt="" srcset="" />
        <span>{{ item.name }}</span>
        <p>{{ item.content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted } from "vue";

export default {
  setup() {
    function getUUID() {
      return Date.now() + Math.random();
    }
    const state = reactive({
      list: [
        {
          id: getUUID(),
          name: "サトウシオノスケ~",
          random: 0,
          line: 1,
          header: require("@/assets/home/header/1.png"),
          content:
            "ややこしい設定とかいらないので年齢問わず使いやすいと思います。",
        },
        {
          id: getUUID(),
          name: "ueda natsu",
          random: 1,
          line: 1,
          header: require("@/assets/home/header/2.png"),
          content:
            "マイナンバーカードのオンライン申請で写真が必要なので使わせていただきました。",
        },
        {
          id: getUUID(),
          name: "千慧",
          random: 0,
          line: 1,
          header: require("@/assets/home/header/3.png"),
          content:
            "今回、マイナンバーカード申請のためこのアプリを使ってWeb申請しました。優しい表情できれいに写せました。",
        },
        {
          id: getUUID(),
          name: "コガリンココガリンコ",
          random: 1,
          line: 1,
          header: require("@/assets/home/header/4.png"),
          content: "顔の歪みまで修正されていました。 満足しています。",
        },
        {
          id: getUUID(),
          name: "burata 615",
          random: 0,
          line: 1,
          header: require("@/assets/home/header/5.png"),
          content:
            "怪我していてスピード写真撮りに行けない時に丁度このアプリ発見。使ってみたら、とても便利でした。",
        },
        {
          id: getUUID(),
          name: "加藤悠",
          random: 1,
          line: 1,
          header: require("@/assets/home/header/6.png"),
          content:
            "このアプリは素晴らしい。スマホで写真を簡単に取れて、同時に無背景に、ちょうどいい程度に美顔をしてくれた。",
        },
        {
          id: getUUID(),
          name: "イブラヒムけんいち",
          random: 0,
          line: 1,
          header: require("@/assets/home/header/7.png"),
          content:
            "背景除去が優れている。 美顔効果が自然にされるのでとても良い",
        },
        {
          id: getUUID(),
          name: "倫太郎",
          random: 1,
          line: 1,
          header: require("@/assets/home/header/8.png"),
          content:
            "適度な補正で若返った。簡単で便利。満足です。無料ではないけど納得の出来映え。",
        },
        {
          id: getUUID(),
          name: "山田太郎",
          random: 0,
          line: 1,
          header: require("@/assets/home/header/9.png"),
          content:
            "撮り直し出来るし化粧しなくても肌が綺麗に写るので安心です。 良いアプリだと思います",
        },
        {
          id: getUUID(),
          name: "ココナッツ",
          random: 1,
          line: 1,
          header: require("@/assets/home/header/10.png"),
          content: "背景も無くしてくれて、写真もきれいに撮れるので助かります。",
        },
        {
          id: getUUID(),
          name: "tosiko tosi",
          random: 0,
          line: 1,
          header: require("@/assets/home/header/11.png"),
          content:
            "普段着で撮影できて便利ですし、画質がとても良く実物以上にきれいに撮影できていて大変満足です。",
        },
        {
          id: getUUID(),
          name: "石井佳代",
          random: 1,
          line: 1,
          header: require("@/assets/home/header/12.png"),
          content:
            "簡単にマイナンバー用の写真を撮ることが出来ました。仕上がりもキレイで良かったです。",
        },
        {
          id: getUUID(),
          name: "白石悦子",
          random: 0,
          line: 1,
          header: require("@/assets/home/header/13.png"),
          content: "大変きれいに仕上がりました! また次回使わせてもらいます。",
        },
        {
          id: getUUID(),
          name: "山崎弥生",
          random: 1,
          line: 1,
          header: require("@/assets/home/header/14.png"),
          content:
            "操作がとても簡単で自然な美顔効果に感動しました。ありがとうございました!",
        },
        {
          id: getUUID(),
          name: "市川雅彦",
          random: 0,
          line: 1,
          header: require("@/assets/home/header/15.png"),
          content:
            "何も考えずとても楽に証明写真ができる。面倒な画像処理はすべて自動でやってくれます。最高!",
        },
        {
          id: getUUID(),
          name: "GE GO",
          random: 1,
          line: 1,
          header: require("@/assets/home/header/16.png"),
          content:
            "いつでもどこでも正確な写真が撮れるだけでなく肌補正が丁度良いです！いいアプリ見つけたなって喜んでます！",
        },
        {
          id: getUUID(),
          name: "別府先生",
          random: 0,
          line: 1,
          header: require("@/assets/home/header/17.png"),
          content: "簡単、便利、修整までしてもらえて良いアプリだと思います",
        },
        {
          id: getUUID(),
          name: "齋藤喜美",
          random: 1,
          line: 1,
          header: require("@/assets/home/header/18.png"),
          content:
            "私が撮影したヘタクソな写真を証明写真にしていただけました。感謝です。",
        },
        {
          id: getUUID(),
          name: "Xochitl Rolfson",
          random: 0,
          line: 1,
          header: require("@/assets/home/header/19.png"),
          content:
            "免許証用や履歴書用などよく使うサイズ選択が容易にできて背景色も変えられ！！とても便利です！！",
        },
        {
          id: getUUID(),
          name: "Rosendo Haag",
          random: 1,
          line: 1,
          header: require("@/assets/home/header/20.png"),
          content:
            "履歴書の写真が必要で初めて使いました。操作がシンプルなので簡単で使いやすく、証明写真背景が選べるのがありがたかったです。",
        },
      ], // 普通的弹幕队列
      bulletlist: [], // 当前正在执行的
      values: "",
      idx: 2,
    });

    function animationend() {
      state.list.push(state.bulletlist.shift());
    }

    onMounted(() => {
      setInterval(() => {
        var item = null;

        if (!item) {
          item = state.list.shift();
        }
        if (item) {
          item.line = state.idx;
          state.idx = (state.idx % 2) + 1;
          state.bulletlist.push(item);
        }
      }, 2600);
    });

    return {
      state,
      animationend,
    };
  },
};
</script>

<style lang="less" scoped>
.user-evaluation {
  background-color: #f9f9f9;
  height: 650px;
  .user-evaluation-title {
    font-weight: 500;
    text-align: center;
    font-size: 40px;
    // margin-top: 80px;
    margin-bottom: 60px;
    position: relative;
    padding-top: 70px;
    .line {
      position: absolute;
      bottom: 10px;
      height: 10px;
      width: 270px;
      left: 590px;
      background-color: #5af0e4;
    }
    p {
      position: relative;
    }
  }
  .flex {
    display: flex;
  }

  
  .bold {
    font-weight: bold;
  }
  .bullet-wrap {
    height: 463px;
    position: relative;
    overflow: hidden;
    // background: #333;
    background-size: cover;
  }
  .bullet-item {
    padding: 24px 24px 0 24px;
    background-color: white;
    border-radius: 16px;
    white-space: nowrap;
    user-select: none;
    position: absolute;
    top: 0;
    left: 0;
    img {
      width: 64px;
    }

    span {
      position: absolute;
      font-weight: 500;
      top: 32px;
      left: 114px;
      color: #000;
      font-size: 28px;
    }

    p {
      font-weight: 500;
      color: #666;
      font-size: 28px;
      margin-top: 24px;
    }
  }
  .bullet-item[data-line="1"] {
    top: 0px;
  }
  .bullet-item[data-line="2"] {
    top: 230px;
  }
  .bullet-animation-0 {
    animation: rightToleft 40s linear both;
  }
  .bullet-animation-1 {
    animation: rightToleft 40s linear both;
  }
  .bullet-animation-2 {
    animation: rightToleft 40s linear both;
  }

  @keyframes rightToleft {
    0% {
      transform: translate(100vw);
    }

    100% {
      transform: translate(-900%);
    }
  }
}
</style>