import UAParser from "ua-parser-js";
import requests from "@/api/axios";
import md5 from 'js-md5';

export async function get_refresh_token(isForce = false) {
    // 打开任意页面都会判断有没有refresh_token，如果没有则会请求refresh_token
    const refresh_token = localStorage.getItem('refresh_token');

    //refresh_token为空
    if (refresh_token === null || isForce) {
        const parser = new UAParser();
        const result = parser.getResult();
        const sign = "browser_type=" + result.browser.name + "&browser_version=" + result.browser.version + "&operating_system=" + result.os.name + "&resolution=" + screen.width + "*" + screen.height;
        const md5_sign = md5(sign).toUpperCase()
        const data = {
            browser_type: result.browser.name,
            browser_version: result.browser.version,
            operating_system: result.os.name,
            resolution: screen.width + "*" + screen.height,
            sign: md5_sign,
        };

        const res = await requests.post("web/oauth/auth", data);
        if (res.code === 200) {
            await get_access_token(res.data.refresh_token);
        }
    } else {
        await get_access_token(refresh_token);
    }


}

export async function get_access_token(refresh_token) {
    const md5_sign = md5("refresh_token=" + refresh_token).toUpperCase();
    const data = {
        refresh_token: refresh_token,
        sign: md5_sign
    }
    const res = await requests.post("web/oauth/token", data);
    if (res.code === 200) {
        localStorage.setItem("refresh_token", res.data.refresh_token);
        localStorage.setItem("access_token", res.data.access_token);
    }

}

