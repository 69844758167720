import { get_refresh_token } from "@/api/get_token/getToken.js";
//对于axios进行二次封装
import axios from "axios";
//底下的代码也是创建axios实例
let requests = axios.create({
  //基础路径
  // baseURL: "http://47.117.116.156:10002/",
  baseURL: "https://leqiidphoto.com/",
  //请求不能超过50S
  timeout: 50000,
});

//请求拦截器（可以在请求发出去之前，做一些事情）
requests.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token')
  if (token) {
    // config.headers["Authorization"] = "Bearer "+ token
    config.headers['token'] = token
  }
  return config;
}, error => {
  // do something with request error
  // console.log('请求出错了') // for debug
  return Promise.reject(error)
});

//响应拦截器（在数据返回之后，做一些事情）
requests.interceptors.response.use(
  async (res) => {
    //响应成功
    // console.log(res, '响应拦截器');

    // token过期,token denied
    if (res.data.code === 10002 || res.data.code === 10001) {
      await get_refresh_token(true);
    }

    return res.data;
  },
  (err) => {
    //响应失败
    // alert("服务器响应数据失败");
  }
);
export default requests;
