<template>
  <div class="map-container">
    <div class="title">
      <p>サイトマップ</p>
    </div>

    <div class="main-container">
      <ul>
        <li>
          <router-link to="home">
            <img src="@/assets/map/icon_1.png" alt="" />
            <span>トップページ</span>
          </router-link>
        </li>
        <li>
          <router-link to="specs">
            <img src="@/assets/map/icon_2.png" alt="" />
            <span>仕様のリスト</span>
          </router-link>
        </li>
        <li>
          <router-link to="use">
            <img src="@/assets/map/icon_3.png" alt="" />
            <span>使り方</span>
          </router-link>
        </li>
        <li>
          <router-link to="resource">
            <img src="@/assets/map/icon_4.png" alt="" />
            <span>人気記事</span>
          </router-link>
        </li>
        <li>
          <router-link to="price">
            <img src="@/assets/map/icon_5.png" alt="" />
            <span>値段</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "map",
};
</script>

<style lang="less" scoped>
.map-container {
  padding-top: 78px;
  .title {
    background-image: url("@/assets/map/line.png");
    background-size: contain;
    background-repeat: no-repeat;
    height: 100px;
    margin-bottom: 48px;
    p {
      text-align: center;
      line-height: 100px;
      font-size: 32px;
      font-weight: 500;
    }
  }

  .main-container {
    ul {
      list-style: none;
      margin-left: 215px;
      li {
        margin-bottom: 24px;
        display: inline-block;
        a {
          display: inline-block;
          background-image: url("@/assets/map/icon.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: right;
          width: 495px;
          border: 1px #cdfffb solid;
          border-radius: 12px;
          color: #333;
          line-height: 120px;
          box-shadow: 0 4px 28px rgba(50, 146, 138, 0.09);
          img {
            margin-left: 25px;
            width: 56px;
          }

          span {
            font-size: 24px;
            margin-left: 25px;
          }
        }
      }

      li:nth-child(2n) {
        margin-left: 25px;
      }
    }
  }
}
</style>