import requests from "@/api/axios.js";

export async function get_article_list(page = 1, page_size = 3) {
    const params = {
        page: page,
        page_size: page_size,
        origin: "pc"
    };
    const res = await requests.request({
        url: "web/articles",
        method: "get",
        params: params,
    });
    return res;
};