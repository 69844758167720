<template>
  <!-- Q&A area -->
  <div class="q-a">
    <h2 class="title">FAQS</h2>
    <p class="desc">
      証明写真に関するよくある質問をまとめました。
      詳細については、証明写真アプリを体験してください。
    </p>

    <p class="sub-title">web作成によくある質問Q&A</p>
    <div class="accordion" id="accordionExample">
      <!-- 1 -->
      <div class="card">
        <div class="card-header" id="headingOne">
          <h2 class="mb-0">
            <button
              class="btn btn-block text-left"
              type="button"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
              @click.prevent="showMine($event)"
            >
              いつお金はかかるのですか？
            </button>
            <img
              v-if="showarray.collapseOne"
              src="@/assets/home/open.png"
              alt=""
            />
            <img v-else src="@/assets/home/close.png" alt="" />
          </h2>
        </div>

        <div
          id="collapseOne"
          class="collapse"
          aria-labelledby="headingOne"
          data-parent="#accordionExample"
        >
          <div class="card-body">
            証明写真の撮影や作成は回数制限なしで無料ですが、気に入ったものを保存する場合のみ有料です!
          </div>
        </div>
      </div>

      <!-- 2 -->
      <div class="card">
        <div class="card-header" id="headingTwo">
          <h2 class="mb-0">
            <button
              class="btn btn-block text-left"
              type="button"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
              @click.prevent="showMine($event)"
            >
              支払い後写真はどこに保存しますか?
            </button>
            <img
              v-if="showarray.collapseTwo"
              src="@/assets/home/open.png"
              alt=""
            />
            <img v-else src="@/assets/home/close.png" alt="" />
          </h2>
        </div>
        <div
          id="collapseTwo"
          class="collapse"
          aria-labelledby="headingTwo"
          data-parent="#accordionExample"
        >
          <div class="card-body">
            写真はお支払い後、自動的にスマートフォンのアルバムに保存されます。また、アプリ左上の註文ページの註文の詳細に写真を再保存することもできます!
          </div>
        </div>
      </div>

      <!-- 3 -->
      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button
              class="btn btn-block text-left"
              type="button"
              data-toggle="collapse"
              data-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
              @click.prevent="showMine($event)"
            >
              支払い後に写真が保存されていない場合はどうしますか?
            </button>
            <img
              v-if="showarray.collapseThree"
              src="@/assets/home/open.png"
              alt=""
            />
            <img v-else src="@/assets/home/close.png" alt="" />
          </h2>
        </div>
        <div
          id="collapseThree"
          class="collapse"
          aria-labelledby="headingThree"
          data-parent="#accordionExample"
        >
          <div class="card-body">
            Google payやApple
            payで不具合が発生している可能性があります。メールボックスjpphoto.service@leqi.aiまたはアプリ内右上-【フィードバック】からご連絡ください。1営業日以内に問題を解決します。
          </div>
        </div>
      </div>
    </div>
    <div class="download">
      <router-link to="/speclist"> 今すぐ証明写真を作成する </router-link>
    </div>

    <p class="sub-title">アプリ作成によくある質問Q&A</p>
    <div class="accordion" id="accordionExample">
      <!-- 4 -->
      <div class="card">
        <div class="card-header" id="headingFour">
          <h2 class="mb-0">
            <button
              class="btn btn-block text-left"
              type="button"
              data-toggle="collapse"
              data-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
              @click.prevent="showMine($event)"
            >
              写真データはどのように保存しますか?
            </button>
            <img
              v-if="showarray.collapseFour"
              src="@/assets/home/open.png"
              alt=""
            />
            <img v-else src="@/assets/home/close.png" alt="" />
          </h2>
        </div>
        <div
          id="collapseFour"
          class="collapse"
          aria-labelledby="headingFour"
          data-parent="#accordionExample"
        >
          <div class="card-body">
            写真は暗号化してクラウドサーバーに保存します。絶対に安全です。180日間保存します。期限が切れると自動的に削除されます!
          </div>
        </div>
      </div>

      <!-- 5 -->
      <div class="card">
        <div class="card-header" id="headingFive">
          <h2 class="mb-0">
            <button
              class="btn btn-block text-left"
              type="button"
              data-toggle="collapse"
              data-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
              @click.prevent="showMine($event)"
            >
              マイナンバーカードの写真を撮ってもいいですか?
            </button>
            <img
              v-if="showarray.collapseFive"
              src="@/assets/home/open.png"
              alt=""
            />
            <img v-else src="@/assets/home/close.png" alt="" />
          </h2>
        </div>
        <div
          id="collapseFive"
          class="collapse"
          aria-labelledby="headingFive"
          data-parent="#accordionExample"
        >
          <div class="card-body">
            マイナンバーカードの写真は、以下の公式要件に従って作成します：<br />
            ファイル形式：jpeg.<br />
            ファイルサイズ：20KB～7MB.<br />
            ピクセルサイズ：幅480～6000ピクセル、高さ480～6000ピクセル
          </div>
        </div>
      </div>

      <!-- 6 -->
      <div class="card">
        <div class="card-header" id="headingSix">
          <h2 class="mb-0">
            <button
              class="btn  btn-block text-left"
              type="button"
              data-toggle="collapse"
              data-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
              @click.prevent="showMine($event)"
            >
              高齢者や子供の証明写真の作成を手伝うことはできますか?
            </button>
            <img
              v-if="showarray.collapseSix"
              src="@/assets/home/open.png"
              alt=""
            />
            <img v-else src="@/assets/home/close.png" alt="" />
          </h2>
        </div>
        <div
          id="collapseSix"
          class="collapse"
          
        >
          <div class="card-body">
            いいですよ。私達の人工知能のアルゴリズムは老人と子供の顔識別に対してもすばらしいことをして、あなたも家の老人と子供のきれいな証明写真を作ることを助けることができます!
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showarray: {
        collapseOne: false,
        collapseTwo: false,
        collapseThree: false,
        collapseFour: false,
        collapseFive: false,
        collapseSix: false,
      },
    };
  },

  methods: {
    showMine($event) {
      var temp = $event.currentTarget.getAttribute("aria-controls");
      for (const key in this.showarray) {
        if (key === temp) {
          this.showarray[key] = true;
        } else {
          this.showarray[key] = false;
        }
      }
    },

    login_success() {
      this.$gtag.event("login_success", "test=====");
    },
  },
};
</script>

<style lang="less" scoped>
.q-a {
  background-color: #fff;
  width: 994px;
  padding-top: 80px;
  text-align: center;
  margin-left: 221px;
  .title {
    padding-top: 60px;
    font-size: 40px;
    font-weight: 500;
  }

  .sub-title {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
  }

  .desc {
    font-size: 16px;
    color: #666;
    margin-top: 30px;
  }

  .accordion {
    margin: 10px auto;
    .card {
      .card-header {
        h2 {
          // position: relative;
          display: flex;
          align-items: center;
          button {
            color: #212b36;
            font-size: 18px;
            text-decoration: none;
          }
          img {
            width: 20px;
            height: 20px;
            // position: absolute;
            right: 0;
          }
        }
      }

      .collapse .card-body {
        color: #666;
        font-size: 16px;
        text-align: left;
      }
    }
  }

  .download {
    margin-top: 80px;
    padding-bottom: 100px;
    margin-left: 50%;
    transform: translateX(-50%);
    a {
      margin-top: 10px;
      background-color: #333;
      padding: 15px 20px;
      border-radius: 5px;
      font-size: 20px;
      color: #fff;
      font-weight: 500;
      box-shadow: #5af0e4 4px 4px;
      text-decoration: none;
    }
  }
}
</style>