<template>
  <div class="app-container">
    <steps class="steps" :current="2"></steps>
    <input
      @change="coverImgChangeHandler"
      type="file"
      accept="image/*"
      ref="iptFileRef"
      hidden
    />
    <div class="choosepic">
      <p class="tips">
        写真サイズ10M以下、jpg/pngのみ対応です。顔と肩を完全に露出する必要があります
      </p>

      <div class="area">
        <div class="choose-area" @click="choosePic">
          <img src="@/assets/choosepic/add.png" alt="" srcset="" />
          <p>写真選択</p>
        </div>

        <div v-show="isShow" class="preview">
          <el-image class="preview-img" :src="previewImg" fit="contain" />
          <div class="close-icon" @click="closePreview">
            <el-image class="img" :src="closeIcon" circle />
          </div>
        </div>

        <div class="overlay-wrapper" v-show="uploading">
          <el-progress
            :percentage="percentage"
            :stroke-width="20"
            striped
            striped-flow
            :duration="30"
            class="progress"
            color="#5DEFE0"
            :show-text="false"
          />
          <p>loading...</p>
        </div>
      </div>
    </div>

    <el-button
      color="#000"
      class="next"
      @click="next"
      :disabled="uploading ? true : false"
    >
      次へ
    </el-button>
    <article-list></article-list>
    <user-evaluation></user-evaluation>
  </div>
</template>

<script>
import Steps from "@/components/Steps.vue";
import ArticleList from "@/components/Articles.vue";
import UserEvaluation from "@/components/UserEvaluation.vue";
import closeimg from "@/assets/choosepic/close.png";
import {
  get_img_url,
  upload_img,
  make_img,
} from "@/api/upload_img/uploadImg.js";
import { ElMessage } from "element-plus";
import { event } from "vue-gtag";

export default {
  data() {
    return {
      isShow: false, //是否展示预览图
      upload_img_url: "",
      img_key: "",
      previewImg: null,
      closeIcon: closeimg,
      uploading: false,
      percentage: 1,
      file: null,
    };
  },

  components: { Steps, ArticleList, UserEvaluation },
  mounted() {
    event("pc-制作流程-进入选择图片页");
  },

  methods: {
    // 点击选择封面，触发图片选择框的点击事件
    choosePic() {
      this.$refs.iptFileRef.click();
    },

    // 图片选择框的 change 事件触发
    coverImgChangeHandler(e) {
      // 1.获取用户选择的文件对象
      const files = e.target.files;
      // 2.判断用户是否选择了文件对象
      if (files.length === 0) {
        // 2.1用户没有选择图片(使用默认图片)
        // 法1
        // this.$refs.imgRef.src = coverImg
        // 法2
        this.previewImg = coverImg;
        return;
      }
      let size = Math.floor(files[0].size);
      if (size > 10 * 1024 * 1024) {
        ElMessage({ message: "写真サイズ10M以下。", type: "error" });
        return;
      }

      this.file = files[0];
      // 2.2用户选择了图片(使用选择的图片)
      // ◆将 File 对象 转成 BASE64 字符串
      // 1.创建 FileReader 对象
      const fr = new FileReader();
      // 2.调用 readAsDataURL 函数，读取文件内容
      fr.readAsDataURL(files[0]);
      // 3.监听 fr 的 onload 事件
      fr.onload = (e) => {
        // 通过 e.target.result 获取到读取的结果，值是 BASE64 格式的字符串
        // 法1
        // this.$refs.imgRef.src = e.target.result
        // 法2
        this.previewImg = e.target.result;
        this.isShow = true;
        // input是通过onchange事件来触发js代码的，由于两次文件是重复的，所以这个时候onchange事件是没有触发到的。把值设为null，则可以重复触发
        this.$refs.iptFileRef.value = null;
      };
    },

    next() {
      event("pc-选择图片页-点击下一步");
      if (this.file == null) {
        ElMessage({
          message: "写真のアップロードをお願いします",
          type: "error",
        });
        return;
      }
      this.uploading = true;
      let timer = setInterval(() => {
        this.percentage += 0.5;
        if (this.percentage >= 90) {
          clearInterval(timer);
        }
      }, 100);

      this.getImageUrl();
    },

    // 通过图片名获取上传地址
    async getImageUrl() {
      const res = await get_img_url(this.file.name, this.file.type);
      if (res.code === 200) {
        this.upload_img_url = res.data.url;
        this.img_key = res.data.key;
      }
      if (res.code === 10001 || res.code === 10002) {
        this.getImageUrl();
      }

      this.uploadToOSS();
    },

    // 获取到上传的oss地址，开始上传图片到oss服务器
    async uploadToOSS() {
      this.uploading = true;
      const res = await upload_img(
        this.upload_img_url,
        this.file,
        this.file.type
      );
      if (res.status === 200) {
        this.make();
      } else {
        ElMessage({ message: res.msg, type: "error" });
        this.uploading = false;
        this.closePreview();
      }
      if (res.code === 10001 || res.code === 10002) {
        this.upload();
      }
    },

    // 制作流程
    async make() {
      const res = await make_img(
        this.img_key,
        this.$route.query.spec_id,
        true,
        null
      );
      if (res.code === 200) {
        this.$router.push({
          path: "/edit",
          query: {
            result_wm: res.data.result_wm,
            result_key: res.data.result_key,
            img_key: this.img_key,
            spec_id: this.$route.query.spec_id,
            width: this.$route.query.width,
            height: this.$route.query.height,
          },
        });
      } else {
        ElMessage({ message: res.msg, type: "error" });
        this.uploading = false;
      }

      if (res.code === 10001 || res.code === 10002) {
        this.make();
      }
    },

    closePreview() {
      this.file = null;
      this.percentage = 0;
      this.isShow = false;
      this.previewImg = null;
    },
  },
};
</script>

<style lang="less" scoped>
.app-container {
  padding-top: 78px;
  background-color: #f6f8fc;
  display: grid;
  .steps {
    margin-top: 40px;
  }

  .choosepic {
    position: relative;
    .tips {
      text-align: center;
      font-size: 14px;
    }
    .area {
      position: relative;
      width: 567px;
      height: 336px;
      background-color: #eaedf2;
      text-align: center;
      margin: 0 auto;
      margin-top: 24px;
      border: 2px dashed #5defe0;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 240px;
        height: 170px;
      }
      p {
        margin-top: 10px;
        color: #666;
        font-size: 14px;
        text-align: center;
      }

      .preview {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        .preview-img {
          width: 100%;
          height: 100%;
        }

        .close-icon {
          position: absolute;
          right: 0;
          width: 60px;
          height: 60px;
          top: 0;
          display: flex;
          background-color: #fff;
          border-radius: 30px;
          align-items: center;
          justify-content: center;
          .img {
            width: 25px;
            height: 25px;
          }
        }
      }

      .overlay-wrapper {
        position: absolute;
        background-color: rgba(255, 255, 255, 0.8);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        .progress {
          position: absolute;
          top: 40%;
          left: 200px;
          right: 200px;
        }
        p {
          text-align: center;
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          font-size: 14px;
          color: #333;
        }
      }
    }
  }

  .next {
    display: inline-block;
    margin: 70px auto 95px;
    cursor: pointer;
    box-shadow: #5af0e4 4px 4px;
    padding: 0;
    width: 260px;
    height: 50px;
    line-height: 50px;
  }
}
</style>