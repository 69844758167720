<template>
  <!-- <my-header></my-header>
  <router-view v-slot="{ Component }" v-if="$route.meta.keepAlive">
    <keep-alive>
      <component :is="Component" />
    </keep-alive>
  </router-view>
  <router-view v-if="!$route.meta.keepAlive"></router-view> -->

  <div>
    <my-header></my-header>
    <router-view></router-view>
    <my-footer></my-footer>
  </div>

</template>


<script>
import MyHeader from "@/components/MyHeader.vue";
import MyFooter from './components/MyFooter.vue';

export default {
  components: {MyHeader, MyFooter}
}
</script>

<style lang="less" scoped>

</style>

