<template>
  <div class="app-container">
    <steps class="steps" :current="1" :show="false"></steps>
    <div class="input-group mb-3 input-search">
      <input
        type="text"
        class="form-control"
        placeholder="検索用途・サイズを入力する"
        aria-label="検索用途・サイズを入力する"
        aria-describedby="basic-addon2"
        v-model="search_words"
      />
      <div class="input-group-append">
        <button type="button" class="btn btn-dark" @click="search_spec">
          検索
        </button>
      </div>
    </div>

    <!-- 栅格系统 -->
    <div class="container spec-list">
      <div class="empty" v-if="spec_list.length === 0">
        <el-empty
          description="検索する仕様がない場合、下部のメールボックスから連絡して追加することができます。"
        />
      </div>

      <div class="row spec-container" v-else>
        <div
          class="col-lg-3 col-md-4 col-6"
          v-for="(item, index) in spec_list"
          @click="selectItem(index)"
        >
          <div class="content">
            <img
              class="select-status"
              :src="
                index === selectedIndex
                  ? require('@/assets/speclist/select.png')
                  : require('@/assets/speclist/unselect.png')
              "
              alt=""
              srcset=""
            />

            <div class="head">
              <div class="width">{{ item.width }}mm</div>
              <div class="height">{{ item.height }}mm</div>
              <img
                class="head-icon"
                src="@/assets/speclist/head.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="spec-info">
              <p class="spec-name">{{ item.name }}</p>
              <p class="spec-size">{{ item.width }}mm x {{ item.height }}mm</p>
            </div>
          </div>
        </div>
      </div>

      <el-button
        class="next"
        @click="toChoosePic"
        :disabled="spec_list.length > 0 ? false : true"
        color="#000"
      >
        次へ
      </el-button>
    </div>
    <article-list></article-list>
    <user-evaluation></user-evaluation>
  </div>
</template>

<script>
import Steps from "@/components/Steps.vue";
import {
  get_spec_list,
  query_spec_list,
} from "@/api/get_spec_list/getSpecList.js";
import ArticleList from "@/components/Articles.vue";
import UserEvaluation from "@/components/UserEvaluation.vue";
import { event } from "vue-gtag";

export default {
  components: { Steps, ArticleList, UserEvaluation },
  name: "speclist",
  data() {
    return {
      selectedIndex: 0,
      spec_list: [],
      search_words: "",
    };
  },

  mounted() {
    event("pc-制作流程-进入规格列表页");
    this.getSpecList();
  },

  methods: {
    async getSpecList() {
      const res = await get_spec_list("common");
      if (res.code === 200) {
        this.spec_list = res.data;
      }
      if (res.code === 10001 || res.code === 10002) {
        this.getSpecList();
      }
    },

    async search_spec() {
      if (this.search_words.trim().length === 0) {
        return this.getSpecList();
      }
      const res = await query_spec_list(this.search_words);
      if (res.code === 200) {
        this.spec_list = res.data;
      }
    },

    toChoosePic() {
      event("pc-规格列表页-点击下一步");
      if (this.spec_list.length > 0) {
        this.$router.push({
          name: "choosepic",
          query: {
            width: this.spec_list[this.selectedIndex].width,
            height: this.spec_list[this.selectedIndex].height,
            spec_id: this.spec_list[this.selectedIndex].spec_id,
          },
        });
      }
    },

    selectItem(index) {
      this.selectedIndex = index;
    },
  },
};
</script>

<style lang="less" scoped>
.app-container {
  padding-top: 78px;
  background-color: #f6f8fc;
  .steps {
    margin-top: 40px;
  }
  .input-search {
    width: 300px;
    margin-left: 50%;
    margin-top: 20px;
    transform: translateX(-50%);
    .form-control {
      font-size: 12px;
      height: 45px;
    }

    .btn-dark {
      font-size: 14px;
      height: 45px;
      width: 80px;
      white-space: nowrap;
      margin: 0;
      padding: 0;
    }
  }

  .spec-list {
    display: grid;
    // width: 1100pt;
    // background-color: cyan;
    .spec-container {
      .content {
        background-color: #fff;
        display: flex;
        height: 70pt;
        margin-top: 10pt;
        border-radius: 5pt;
        cursor: pointer;
        .select-status {
          width: 10pt;
          height: 10pt;
          align-self: center;
          margin-left: 5pt;
        }

        .head {
          margin-left: 5pt;
          width: 60pt;
          height: 70pt;
          position: relative;
          display: flex;
          justify-content: center;
          font-size: 10pt;
          text-align: center;
          .width {
            position: absolute;
            bottom: 3pt;
            height: 12pt;
            color: #8f97b2;
          }

          .height {
            position: absolute;

            transform: rotateZ(-90deg);
            width: 70pt;
            left: -30pt;
            top: 30pt;
            color: #8f97b2;
          }

          .head-icon {
            align-self: center;
            width: 30pt;
            // height: 40pt;
          }
        }

        .spec-info {
          font-size: 10pt;
          display: flex;
          flex-direction: column;
          justify-content: center;
          // width: 100pt;
          p {
            margin: 0 5pt;
            // background-color: #fff;
          }
          .spec-name {
            color: #333;
          }
          .spec-size {
            color: #666;
          }
        }
      }
    }

    .next {
      margin: 42pt auto 50pt;
      padding: 0;
      height: 35pt;
      line-height: 35pt;
      width: 180pt;
      box-shadow: #5af0e4 3pt 3pt;
      font-size: 10pt;
    }
  }
}
</style>