<template>
  <div class="footer-container">
    <div class="footer-top">
      <div class="one">
        <div class="logo">
          <img src="@/assets/home/logo_title.png" alt="" />
        </div>

        <div class="email">
          <img src="@/assets/footer/email.png" alt="" />
          <span>idphotoweb.service@leeta.ai</span>
        </div>

        <div class="share">
          <a href="https://www.facebook.com/profile.php?id=100086441799260"
            ><img
              class="mes1-img4 mes1-img3"
              src="@/assets/footer/facebook.png"
          /></a>
          <a href="https://twitter.com/leqi123"
            ><img class="mes1-img3" src="@/assets/footer/twitter.png"
          /></a>
          <a href="https://www.tiktok.com/@idphoto?lang=zh-Hant-TW"
            ><img class="mes1-img3" src="@/assets/footer/tiktok.png"
          /></a>
        </div>
      </div>

      <div class="two">
        <ul>
          <li><router-link to="/"> トップページ</router-link></li>
          <li>
            <router-link to="/use"> 使り方 </router-link>
          </li>
          <li>
            <router-link to="/price"> 値段 </router-link>
          </li>
          <li><router-link to="/download">すぐダウンロード</router-link></li>
        </ul>
      </div>

      <div class="three">
        <ul>
          <li>
            <router-link to="/specs"> 仕様のリスト </router-link>
          </li>
          <li>
            <router-link to="/resource"> 人気記事 </router-link>
          </li>
          <li>
            <router-link to="/map"> サイトマップ </router-link>
          </li>
        </ul>
      </div>

      <div class="four">
        <ul>
          <li>
            <a
              href="https://apps.apple.com/jp/app/id1626934418?l=ja"
              onclick="home_apple()"
            >
              <img
                class="footer-img1"
                src="@/assets/footer/app_store.png"
                alt=""
              />
            </a>
          </li>
          <li>
            <a
              href="https://play.google.com/store/apps/details?id=com.leqi.idphoto"
            >
              <img
                class="footer-img2"
                src="@/assets/footer/google_store.png"
                alt=""
              />
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="footer-bottom">
      <p>© Copyright 2023 LEETA  TECHNOLOGY  PTE.LTD.  All rights reserved</p>
      <a href="https://leqiidphoto.com/privacy/"> 個人情報保護方針 </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyFooter",
};
</script>

<style lang="less" scoped>
.footer-container {
  .footer-top {
    display: flex;
    justify-content: space-between;
    height: 260px;
    font-size: 16px;
    .one {
      margin-top: 78px;
      white-space: nowrap;
      margin-left: 120px;
      .logo {
        margin-bottom: 15px;
        margin-left: -5px;
        img {
          width: 180px;
        }
      }

      .email {
        margin-bottom: 10px;
        >img {
          width: 24px;
        }

        span {
          margin-left: 10px;
          color: #666;
          font-weight: 500;
        }
      }

      .share {
        a {
          width: 40px;
          height: 40px;
          display: inline-block;
          img {
            margin-left: 12px;
            width: 16px;
          }
        }
      }
    }

    .two {
      text-align: center;
      margin-top: 78px;
      white-space: nowrap;
      li {
        margin-bottom: 6px;
        a {
          color: #666;
          text-decoration: none;
          white-space: nowrap;
          font-weight: 500;
        }
      }
    }

    .three {
      text-align: center;
      margin-top: 78px;
      white-space: nowrap;
      li {
        margin-bottom: 6px;
        a {
          color: #666;
          font-weight: 500;
        }
      }
    }

    .four {
      text-align: right;
      margin-right: 120px;
      margin-top: 78px;
      white-space: nowrap;
      li a img {
        width: 150px;
        margin-bottom: 17px;
      }
    }
    li {
      list-style: none;
      a {
        color: #666;
        text-decoration: none;
        white-space: nowrap;
      }
    }

    li:hover a {
      color: #199389;
    }
  }

  .footer-bottom {
    margin: 0px 90px;
    margin-bottom: 68px;
    display: flex;
    justify-content: space-between;
    p {
      color: #666;
      font-size: 16px;
      margin-left: 20px;
    }
    a {
      color: #666;
      text-decoration: none;
      white-space: nowrap;
      font-size: 16px;
      margin-right: 20px;
    }
  }
}
</style>