<template>
  <div class="download-container">
    <div class="main-container">
      <p class="desc">期間限定</p>
      <div class="line"></div>
      <p class="content">
        広告なし、登録なし！<br />証明写真を30秒で素早く作成！<br />自宅でも使用できるプロの証明写真アプリ！
      </p>
      <div class="ios_appstore">
        <a
          href="https://apps.apple.com/jp/app/id1626934418?l=ja"
          ><img src="@/assets/download/appstore.png" alt="" class="appstore"
        /></a>
      </div>

      <div class="play_appstore">
        <a
          href="https://play.google.com/store/apps/details?id=com.leqi.idphoto"
        >
          <img src="@/assets/download/google.png" alt="" class="google"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "download",
};
</script>

<style lang="less" scoped>
.download-container {
  padding-top: 78px;
  background-image: url("@/assets/download/download_bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 861px;
  text-align: left;

  .main-container {
    .desc {
      // display: inline-block;
      width: 165px;
      height: 55px;
      // background-color: red;
      font-size: 40px;
      font-weight: 500;
      color: #333;
      margin-left: 322px;
      margin-top: 245px;
    }

    .line {
      width: 39px;
      height: 4px;
      background-color: #333;
      margin-left: 175px;
      margin-top: 19px;
    }
    .content {
      margin-top: 31px;
      margin-left: 174px;
      color: #666;
      font-size: 24px;
      line-height: 30px;
    }

    .ios_appstore,
    .play_appstore {
      margin-top: 60px;
      width: 154px;
      height: 48px;
      display: inline-block;
      img {
        width: 100%;
      }
    }

    .ios_appstore {
      margin-left: 174px;
    }

    .play_appstore {
      margin-left: 24px;
    }
  }
}
</style>