import requests from "@/api/axios";
export async function print_typesetting(spec_id, start_color, end_color, result_key, size) {
    const data = {
        spec_id: spec_id,
        start_color: start_color,
        end_color: end_color,
        result_key: result_key,
        size: size
    };
    return await requests.post('web/photo/typesetting', data);
}

export async function makeOrder(data) {
    return await requests.post("web/order", data);
}

export async function downloadPics(order_no) {
    return await requests.get("web/photos", { params: { "order_no": order_no } });
}