import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import "@/utils/index.css";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import VueGtag from 'vue-gtag'

const app = createApp(App);
app.use(VueGtag, {
    config: { id: "G-0Z0GXR3N4B" },
    config: { id: "AW-10876672391" }
})
app.use(router);
app.use(ElementPlus)
app.mount('#app');

