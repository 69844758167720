<template>
  <div class="home">
    <div class="banner">
      <img src="@/assets/home/banner.png" alt="" srcset="" />
      <router-link to="/speclist" @click="toSpecList">
        <div class="start_make">
          <img src="@/assets/home/start_make.png" alt="" srcset="" />
        </div>
      </router-link>
    </div>
    <div class="reason">
      <img src="@/assets/home/reason.png" alt="" srcset="" />
      <div class="download">
        <router-link to="/speclist" @click="toSpecList">
          今すぐ証明写真を作成する
        </router-link>
      </div>
    </div>
    <div class="make">
      <img src="@/assets/home/make.png" alt="" srcset="" />
      <div class="download">
        <router-link to="/speclist" @click="toSpecList"
          ><div class="article-download">
            <span>今すぐ証明写真を作成する</span>
          </div></router-link
        >
        <a
          href="https://apps.apple.com/jp/app/id1626934418?l=ja"
          class="app-store"
        >
          <img src="@/assets/home/app_store.png" alt="" srcset="" />
          <span>App Store</span>
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.leqi.idphoto"
          class="play-store"
        >
          <img src="@/assets/home/play_store.png" alt="" srcset="" />
          <span>Play Store</span>
        </a>
      </div>
    </div>
    <div class="use">
      <img src="@/assets/home/use.png" alt="" srcset="" />
      <div class="download">
        <router-link to="/speclist" @click="toSpecList">
          今すぐ証明写真を作成する
        </router-link>
      </div>
    </div>
    <article-list></article-list>
    <user-evaluation></user-evaluation>
    <my-faq></my-faq>
    <div
      :class="
        download_bottom_bar_isShow
          ? 'download-bottom-bar download-bottom-bar-show'
          : 'download-bottom-bar download-bottom-bar-hide'
      "
    >
      <router-link to="/speclist" @click="toSpecList">
        <p>今すぐ証明写真を作成する</p>
      </router-link>
    </div>
  </div>
</template>

<script>
import ArticleList from "@/components/Articles.vue";
import UserEvaluation from "@/components/UserEvaluation.vue";
import MyFaq from "@/components/MyFaq.vue";
import { event } from "vue-gtag";
export default {
  name: "Home",
  components: { ArticleList, UserEvaluation, MyFaq },
  data() {
    return {
      download_bottom_bar_isShow: false,
    };
  },
  methods: {
    // 获取页面滚动距离
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 300) {
        this.download_bottom_bar_isShow = true;
      } else {
        this.download_bottom_bar_isShow = false;
      }
    },

    toSpecList() {
      event("pc-首页-点击去制作入口");
    },
  },

  mounted() {
    event("pc-进入首页");
    window.addEventListener("scroll", this.handleScroll);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
.home {
  padding-top: 78px;
  padding-bottom: 100px;
  font-size: 0; //消除图片间的空隙
  img {
    width: 100%;
  }
  .banner {
    position: relative;
    .start_make {
      position: absolute;
      bottom: 58px;
      right: 337px;
      cursor: pointer;
      transition: all 0.6s;
      img {
        width: 547px;
      }
    }

    .start_make:hover {
      scale: 98%;
    }
  }

  .reason {
    position: relative;
    .download {
      left: 0;
      bottom: 35px;
      margin-top: 80px;
      margin-left: 50%;
      transform: translateX(-50%);
      position: absolute;
      a {
        margin-top: 10px;
        background-color: #333;
        padding: 15px 20px;
        border-radius: 12px;
        font-size: 20px;
        color: #fff;
        font-weight: 500;
        box-shadow: #5af0e4 4px 4px;
        text-decoration: none;
      }
    }
  }

  .make {
    position: relative;
    .download {
      position: absolute;
      left: 130px;
      bottom: 150px;
      right: 600px;
      display: flex;
      justify-content: space-between;
      .article-download,
      .app-store,
      .play-store {
        // width: 200px;
        padding: 0 20px;
        height: 68px;
        line-height: 68px;
        white-space: nowrap;
        color: #333;
        display: flex;
        align-items: center;
        // justify-content: center;
        font-size: 18px;
        text-align: center;
        font-weight: 600;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: 0px 6px 22px 0px rgba(41, 148, 139, 0.1);
        img {
          margin-right: 15px;
          width: 44px;
          height: 44px;
        }
      }

      .article-download {
        font-size: 20px;
        background-color: #333;
        color: #fff;
        box-shadow: #5af0e4 4px 4px;
      }
    }
  }

  .use {
    position: relative;
    margin-bottom: 60px;
    .download {
      left: 0;
      margin-top: 35px;
      margin-left: 50%;
      transform: translateX(-50%);
      position: absolute;
      a {
        margin-top: 10px;
        background-color: #333;
        padding: 15px 20px;
        border-radius: 12px;
        font-size: 20px;
        color: #fff;
        font-weight: 500;
        box-shadow: #5af0e4 4px 4px;
        text-decoration: none;
      }
    }
  }

  .download-bottom-bar {
    background-color: #333;
    box-shadow: #5af0e4 4px 4px;
    height: 70px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.3s;
    a {
      text-decoration: none;
      p {
        color: #fff;
        font-size: 20px;
        text-align: center;
        line-height: 70px;
        white-space: nowrap;
        font-weight: 500;
      }
    }
  }

  .download-bottom-bar:hover {
    background-color: #5af0e4;
    a {
      p {
        color: #333;
      }
    }
  }

  .download-bottom-bar-hide {
    height: 0px;
  }

  .download-bottom-bar-show {
    height: 70px;
  }
}
</style>
