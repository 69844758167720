<template>
  <div class="app-container">
    <el-button
      color="#F6F8FC"
      style="width: 32px; height: 32px"
      circle
      @click="this.$router.go(-1)"
      class="back"
      v-show="show"
    >
      <img src="@/assets/steps/back.png" alt="" />
    </el-button>
    <div class="line-container">
      <div v-for="(item, index) in titles">
        <p
          :class="
            index < current
              ? 'line-title line-title-select'
              : 'line-title line-title-unselect'
          "
        >
          {{ item }}
        </p>

        <div v-if="index == 0" class="line-start"></div>

        <div
          v-if="index != titles.length - 1"
          :class="index < current - 1 ? 'line-solid' : 'line-dash'"
        ></div>
        <div
          v-else
          :class="index == current - 1 ? 'line-solid-end' : 'line-dash-end'"
        ></div>

        <img
          v-if="index < current"
          src="@/assets/steps/select.png"
          alt=""
          srcset=""
        />
        <img v-else src="@/assets/steps/unselect.png" alt="" srcset="" />
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowLeft } from "@element-plus/icons-vue";

export default {
  props: {
    current: {
      default: 1,
      type: Number,
    },
    show: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      titles: [
        "1.サイズを選択",
        "2.写真をアップロードします",
        "3.写真編集",
        "4.写真を保存する",
      ],
      ArrowLeft: ArrowLeft,
    };
  },
};
</script>

<style lang="less" scoped>
.app-container {
  height: 80px;
  position: relative;
  margin: 0 320px;
  .back {
    position: fixed;
    cursor: pointer;
    left: 120px;
    top: 120px;
    z-index: 99;
  }
  .line-container {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .line-title {
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      font-size: 16px;
      white-space: nowrap;
    }

    .line-title-select {
      color: #0c0c0c;
    }

    .line-title-unselect {
      color: #666;
    }

    .line-start {
      border-top: 1px #5defe0 solid;
      position: absolute;
      top: 50%;
      width: 100px;
      left: 0;
    }

    .line-solid {
      border-top: 1px #5defe0 solid;
      position: absolute;
      top: 50%;
      width: 200px;
    }

    .line-dash {
      border-top: 1px #999 dashed;
      position: absolute;
      top: 50%;
      width: 200px;
    }
    .line-dash-end {
      border-top: 1px #999 dashed;
      position: absolute;
      top: 50%;
      width: 90px;
      right: 0;
    }

    .line-solid-end {
      border-top: 1px #5defe0 solid;
      position: absolute;
      top: 50%;
      width: 90px;
      right: 0;
    }

    img {
      width: 24px;
      position: relative;
    }
  }
}
</style>