import requests from "@/api/axios";

export async function get_spec_category() {
    return await requests.get("web/specs/category");
}
// 根据类别获取规格列表
export async function get_spec_list(keyword) {
    return await requests.get("web/specs/" + keyword);
}

// 搜索规格
export async function query_spec_list(keyword) {
    return await requests.get("web/specs", { params: { keyword: keyword } });
}